import React from 'react'
import {
  createBrowserRouter,
  Navigate,
  RouterProvider
} from 'react-router-dom'
import { HomeTemplate } from './components/templates/HomeTemplate/Component'
import { NotFoundTemplate } from './components/templates/NotFoundTemplate/Component'
import { PageTemplate } from './components/templates/PageTemplate/Component'

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomeTemplate />,
    loader: () => (<p>Loading..</p>)
  }, {
    path: '/404',
    element: <NotFoundTemplate />,
    loader: () => (<p>Loading..</p>)
  }, {
    path: '/:slug',
    element: <PageTemplate />,
    loader: () => (<p>Loading..</p>)
  }, {
    path: '*',
    element: <Navigate to='/404' />,
    loader: () => (<p>Loading..</p>)
  }

])

function Router (): JSX.Element {
  return (
    <RouterProvider router={router} />
  )
}

export default Router
