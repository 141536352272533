import { BackgroundType, ButtonStyleType, ButtonType, DataInterface, SocialMediaType, AppButtonType } from '@customizables/constants'

export const customizableData: DataInterface = {
  general: {
    button: {
      buttonStyle: ButtonStyleType.Gradient,
      buttonVariant: ButtonType.Basic
    },
    metatags: {
      locale: 'nl',
      siteTitle: 'ZINinNGT',
      robots: 'all'
    },
    globalEmail: 'info@zin-in-ngt.nl',
    logoAlt: 'Alt',
    companyName: 'ZINinNGT',
    socialMedia: [
      // Maximum 6 items
      // All possibilities are shown here, please delete all that are not used.
      {
        title: 'Link title',
        type: SocialMediaType.Mail
      }
    ],
    serviceMenu: [
      {
        label: 'Privacy & voorwaarden',
        href: '/voorwaarden',
        title: 'Privacy & voorwaarden'
      }
    ]
  },
  home: {
    subtitle: '',
    title: 'ZINinNGT',
    description: `De app ZINinNGT is ontwikkeld voor horende ouders van dove kinderen ter ondersteuning van de
communicatie met hun kind. ZINinNGT biedt video’s van zinnen in Nederlandse Gebarentaal (NGT)
die vaak gebruikt worden tijdens ouder-kind interactie.

Door middel van een zoekfunctie kunnen bij verschillende woorden en thema’s naar
voorbeeldzinnen gezocht worden. Alle voorbeeldzinnen worden gebaard door dove gebaarders en
bij iedere video is zowel een Nederlandse als een letterlijke gebaar-voor-gebaar vertaling
beschikbaar. Ook bevat de app uitleg over de grammatica van NGT. Elke video kan op verschillende
snelheden en vanuit drie verschillende hoeken worden afgespeeld.

Deze app maakt onderdeel uit van een onderzoek van de [Universiteit van Amsterdam](https://www.signlab-amsterdam.nl/index.html) in
samenwerking met de [Koninklijke Auris Groep](https://auris.nl/)`,
    buttons: [
      {
        label: 'App Store',
        type: AppButtonType.Apple,
        url: 'https://apps.apple.com/us/app/zininngt/id6472268826',
        title: 'App Store'
      },
      {
        label: 'Google Play',
        type: AppButtonType.GooglePlay,
        url: 'https://play.google.com/store/apps/details?id=nl.zininngt.app',
        title: 'Google Play'
      }
    ],
    image: {
      alt: 'ZINinNGT app'
    },
    backgroundType: BackgroundType.Gradient, // Make sure to set the correct data in the variables.css file
    seo: {
      pageTitle: 'ZINinNGT',
      pageDesc: 'Leer Nederlandse Gebarentaal met onze educatieve videos'
    }
  },
  pages: [
    {
      lastUpdate: new Date('2023-11-30'), // YYYY-MM-DD
      slug: '/voorwaarden',
      intro: {
        title: 'Privacy & voorwaarden',
        desc: ''
      },
      content: [
        {
          title: 'Privacyverklaring app ZINinNGT',
          content: `Versie 1.0&nbsp;  
          10 Maart 2024
          \n\nDe ZINinNGT app (hierna app) wordt aangeboden vanuit een samenwerkingsverband tussen de Universiteit van Amsterdam en Auris. De procedures van de Universiteit van Amsterdam zijn leidend in de verwerking van persoonsgegevens door de app.
          \n\nWij hechten veel waarde aan jouw privacy. Bij de ontwikkeling van deze app hebben we de Algemene Verordening Gegevensbescherming (“AVG”) als uitgangspunt genomen en maatregelen getroffen om jouw persoonsgegevens op een juiste manier te verwerken en te beschermen.
          \n\nIn deze privacyverklaring leggen we uit welke persoonsgegevens we verwerken, hoe en waarom we dit doen en hoe we deze persoonsgegevens beschermen. Daarnaast leggen we uit welke rechten jij hebt ten aanzien van de verwerking van jouw persoonsgegevens. Deze privacyverklaring heeft alleen betrekking op het verwerken van persoonsgegevens in de app.`
        }, {
          title: 'Welke persoonsgegevens',
          content: `We begrijpen het belang van het bewaken van de persoonsgegevens van gebruikers. Het uitgangspunt bij gegevensverzameling is om zo min mogelijk gegevens vast te leggen.
          \n\nToegang app: Wanneer je gebruik wilt maken van de app zullen wij geen (persoons)gegevens vragen omdat je geen persoonlijke account krijgt. Je gebruikt de app op een anonieme manier. Wij gebruiken niet jouw locatiegegevens of IP-adres. Wij bewaren geen persoonlijke identificeerbare informatie van gebruikers en we slaan ook geen gebruikersprofielen op.
          \n\nOptimaliseren gebruikerservaring van de app: Wij gebruiken jouw IP-adres ten behoeve van crash-reporting. Wij gebruiken daarvoor Firebase Crashlytics. Deze gegevens worden gebruikt met als doel om de app functioneel ter beschikking te stellen.`
        }, {
          title: 'Persoonsgegevens en derden',
          content: `Voor wetenschappelijke doeleinden verzamelen we gegevens over het gebruik van de app. We gebruiken hiervoor Google Analytics. De gegevens die Google Analytics verzamelt, worden uitsluitend gebruikt door de onderzoekers van het wetenschappelijk onderzoeksproject ZINinNGT van de Universiteit van Amsterdam en Auris. Google Analytics verzamelt niet-herleidbare gegevens zoals aantal gebruikers, hoeveel keer een video is afgespeeld en welke video’s als favoriet worden aangemerkt door gebruikers, daarnaast verwerkt Google Analytics ook jouw IP-adres. Deze data zijn echter niet gekoppeld aan specifieke gebruikers. Wij zullen geen gegevens delen met andere partijen, tenzij er een wettelijke verplichting is vanuit een daarvoor geautoriseerde partij. De gegevens worden niet gebruikt voor marketingdoeleinden.
          \n\nWij delen alleen technische gegevens met het bedrijf YipYip B.V.. YipYip B.V. heeft de app ontwikkeld en helpt ons bij het onderhoud van de app. De gegevens worden opgeslagen in de EU via de gecontracteerde beherende en hostende partij.`
        }, {
          title: 'Contact opnemen',
          content: 'Mocht je een privacy-vraag hebben over deze app, dan kun je altijd contact met ons opnemen via info@zin-in-ngt.nl. De Universiteit van Amsterdam ziet erop toe dat je vraag zo snel en volledig mogelijk beantwoord wordt. Dit kan een algemene vraag zijn, maar ook om gebruik te maken van je rechten. Belangrijk is om je vraag zo volledig mogelijk te beschrijven, wat je belang hierbij is en te verduidelijken wie je bent. Zo kunnen we je gerichter en sneller van een antwoord voorzien.'
        },
        {
          title: 'Toezicht',
          content: `De Universiteit van Amsterdam heeft een functionaris gegevensbescherming (FG). De FG houdt toezicht op de naleving van de privacywetgeving binnen de Universiteit van Amsterdam. De FG is onafhankelijk. De FG is bovendien de contactpersoon voor vragen die over privacy gaan, zowel voor jou als betrokkene als voor de toezichthouder, je kunt contact opnemen met de FG via fg@uva.nl.
          \n\nVoor verzoeken inzake de uitoefening van je rechten (rechten van betrokkene) kun je contact opnemen met [jz-privacy-bb@uva.nl](mailto:jz-privacy-bb@uva.nl).
          \n\nVoor overige vragen en klachten over de verwerking van jouw gegevens kun je contact opnemen met avg@uva.nl.
          \n\nMochten jij en de Universiteit van Amsterdam er niet uitkomen, dan kun je je altijd wenden tot de Autoriteit Persoonsgegevens. Op www.autoriteitpersoonsgegevens.nl lees je hoe je een klacht kunt indienen.`
        },
        {
          title: 'Aansprakelijkheid',
          content: `De Universiteit van Amsterdam is verantwoordelijk voor de verwerking van de gegevens in overeenstemming met deze verklaring en is aansprakelijk voor eventuele schade die, of nadeel dat wordt veroorzaakt door het niet-nakomen van deze verklaring of van de daaraan ten grondslag liggende wettelijke bepalingen.
          \n\nDe Universiteit van Amsterdam aanvaardt op geen enkele wijze aansprakelijkheid voor eventuele schade, van welke aard dan ook, die wordt toegekend aan handelingen en/of beslissingen die zijn gebaseerd op het gebruik van de app of die daaraan worden toegedicht.`
        },
        {
          title: 'Wijzigingen in deze privacyverklaring',
          content: 'Deze privacyverklaring kan altijd worden gewijzigd. Het is verstandig deze privacyverklaring regelmatig te raadplegen, zodat je bekend bent met eventuele wijzigingen.'
        }
      ],
      seo: {
        pageTitle: 'Privacy & Voorwaarden - ZINinNGT',
        pageDesc: 'Leer Nederlandse Gebarentaal met onze educatieve videos'
      }
    }
  ]

}
