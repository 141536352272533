import React from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { customizableData as data } from '@customizables/data'
import style from './style.module.css'
import { Header } from '@root/components/atoms/Header/Component'
import { SocialMedia } from '@root/components/atoms/SocialMedia/Component'
import { Grid } from '@root/components/atoms/Grid/Component'
import { hasValue } from '@root/lib/helpers'
import { getIcon } from '@root/components/atoms/IconButton/Icon'
import InternalOrExternalLink from '@root/lib/link/Component'
import { Button } from '@root/components/atoms/Button/Component'
import ReactMarkdown from 'react-markdown'
import { Footer } from '@root/components/atoms/Footer/Component'
import { useMetaTags } from '@root/lib/hooks/use-meta-tags'
import useWindowSize from '@root/lib/hooks/use-window-size'
import { AppButtonType } from '@root/customizables/constants'

export const PageTemplate = (): JSX.Element => {
  const { slug } = useParams()
  const windowSize = useWindowSize()
  const pageIdx = data.pages.findIndex(page => page.slug === `/${slug ?? ''}`)

  useMetaTags(pageIdx === -1 ? null : { ...data.home.seo, ...data.pages[pageIdx].seo, ...data.general.metatags })

  if (pageIdx === -1) {
    return <Navigate to='/404' replace />
  }

  const pageData = data.pages[pageIdx]

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        {windowSize.isTablet && (
          <Header />
        )}
        <SocialMedia isPage />
      </div>

      <div className={style.content}>
        <Grid className={style['intro-grid']}>
          <div className={style.intro}>
            <h1>{pageData.intro.title}</h1>
            <p className='larger'>{pageData.intro.desc}</p>

            <Button iconPosition='right' wrapperClassName={style.button} as='link' href={`mailto:${data.general.globalEmail}`} buttonType={AppButtonType.ArrowRight} key='email-button'>Neem contact op</Button>

            {hasValue(data.general.globalEmail) && (
              <p className={style.email}>
                {getIcon('mail')} <InternalOrExternalLink href={`mailto:${data.general.globalEmail}`}>{data.general.globalEmail}</InternalOrExternalLink>
              </p>
            )}
          </div>
        </Grid>

        {
          pageData.content.map(page => {
            return (
              <Grid key={page.title} className={style['content-grid']}>
                <div className={style.content}>
                  <h2>{page.title}</h2>
                  <ReactMarkdown
                    components={{
                      a ({ node, ...props }) {
                        return <InternalOrExternalLink {...props} />
                      }
                    }}
                  >
                    {page.content}
                  </ReactMarkdown>
                </div>
              </Grid>
            )
          })
        }
        <Grid className={style['content-grid']}>
          <div className={style.content}>
            <p className={style.update}>
              Laatst geüpdatet op {new Intl.DateTimeFormat(document.documentElement.lang).format(pageData.lastUpdate)}
            </p>
          </div>
        </Grid>
      </div>
      <div className={style.wrapper}>
        <Footer />
      </div>
    </div>
  )
}
