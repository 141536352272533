const WhatsappIcon = (): JSX.Element => {
  return (
    <svg width='12' height='13' viewBox='0 0 12 13' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path d='M0 12.0097L0.861668 8.80865C0.166754 7.56168 -0.0540267 6.10658 0.239839 4.71034C0.533704 3.31411 1.3225 2.07041 2.46149 1.20747C3.60048 0.344518 5.01326 -0.0797919 6.44057 0.0123968C7.86789 0.104585 9.21401 0.707089 10.2319 1.70935C11.2498 2.71161 11.8712 4.04639 11.9821 5.46876C12.093 6.89114 11.6859 8.30569 10.8356 9.45285C9.98524 10.6 8.74868 11.4028 7.3528 11.714C5.95693 12.0252 4.49538 11.8238 3.23634 11.1468L0 12.0097ZM3.39239 9.94898L3.59255 10.0674C4.50449 10.6066 5.56963 10.8297 6.62197 10.702C7.67432 10.5743 8.65475 10.1029 9.4105 9.36141C10.1662 8.61988 10.6548 7.64982 10.8001 6.60239C10.9454 5.55496 10.7393 4.48901 10.2138 3.57065C9.68836 2.65228 8.87309 1.93309 7.89505 1.52515C6.91701 1.11721 5.83116 1.04344 4.8067 1.31533C3.78224 1.58722 2.87672 2.1895 2.23126 3.02831C1.5858 3.86712 1.23665 4.89535 1.23822 5.95275C1.23737 6.82951 1.48046 7.68931 1.94045 8.43644L2.06597 8.64285L1.58425 10.4295L3.39239 9.94898Z' />
      <path fillRule='evenodd' clipRule='evenodd' d='M8.25021 6.7747C8.13287 6.6803 7.99549 6.61386 7.84852 6.58044C7.70155 6.54702 7.54887 6.54749 7.40211 6.58183C7.1816 6.67319 7.03912 7.01833 6.89664 7.1909C6.8666 7.23227 6.82245 7.26129 6.77248 7.2725C6.72252 7.28371 6.67017 7.27636 6.62525 7.25181C5.81797 6.93643 5.1413 6.35808 4.70516 5.61068C4.66795 5.56404 4.65035 5.50483 4.65604 5.4455C4.66174 5.38617 4.69029 5.33136 4.73569 5.29261C4.8946 5.13569 5.01128 4.94134 5.07493 4.72752C5.08906 4.49168 5.03489 4.25676 4.91888 4.05077C4.82919 3.76198 4.6585 3.50484 4.42698 3.30973C4.30757 3.25616 4.17517 3.23819 4.04575 3.25801C3.91633 3.27782 3.79542 3.33456 3.69761 3.42139C3.52781 3.56751 3.39304 3.74977 3.30321 3.95473C3.21338 4.1597 3.17079 4.38215 3.17858 4.60571C3.1791 4.73125 3.19505 4.85625 3.22607 4.97792C3.30485 5.27025 3.42599 5.54953 3.58567 5.80694C3.70087 6.00412 3.82657 6.19501 3.96222 6.3788C4.40308 6.98246 4.95725 7.49499 5.59396 7.88796C5.91347 8.08765 6.25496 8.25001 6.61168 8.37184C6.98224 8.53938 7.39137 8.60369 7.79563 8.55794C8.02596 8.52317 8.24419 8.43246 8.43112 8.2938C8.61805 8.15514 8.76795 7.97278 8.86762 7.76276C8.92619 7.63589 8.94396 7.49405 8.91851 7.35671C8.85745 7.07585 8.48089 6.91005 8.25021 6.7747Z' />
    </svg>
  )
}

const LinkedinIcon = (): JSX.Element => {
  return (
    <svg width='12' height='12' viewBox='0 0 12 12' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M0.886875 0C0.397219 0 0 0.385125 0 0.859594V11.1407C0 11.6152 0.397266 12 0.886875 12H11.1131C11.603 12 12 11.6152 12 11.1405V0.859594C12 0.385125 11.603 0 11.1131 0H0.886875ZM3.64659 4.64001V10.0424H1.8509V4.64001H3.64659ZM3.76495 2.96924C3.76495 3.48768 3.37518 3.90253 2.74912 3.90253L2.73735 3.90248C2.13468 3.90248 1.74506 3.48764 1.74506 2.9692C1.74506 2.43899 2.14645 2.03573 2.76103 2.03573C3.37518 2.03573 3.75323 2.43899 3.76495 2.96924ZM6.43607 10.0424H4.64053C4.64053 10.0424 4.66406 5.14701 4.64062 4.6402H6.43621V5.40489C6.67491 5.03685 7.10211 4.51335 8.05457 4.51335C9.23596 4.51335 10.1217 5.28553 10.1217 6.94486V10.0424H8.32621V7.15261C8.32621 6.42628 8.0662 5.9309 7.4166 5.9309C6.92043 5.9309 6.62498 6.26498 6.49523 6.58771C6.44779 6.70289 6.43607 6.86456 6.43607 7.02581V10.0424Z' />
    </svg>

  )
}

const InstagramIcon = (): JSX.Element => {
  return (
    <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='11.375' y='11.375' width='10.75' height='10.75' rx='3.125' transform='rotate(-180 11.375 11.375)' stroke='currentColor' strokeWidth='1.25' />
      <circle cx='6' cy='6' r='2.375' transform='rotate(-180 6 6)' stroke='currentColor' strokeWidth='1.25' />
      <path d='M8.87822 2.2501C8.53135 2.2501 8.2501 2.529 8.2501 2.87822C8.2501 3.2251 8.53135 3.50635 8.87822 3.50635C9.2251 3.50635 9.50635 3.2251 9.50635 2.87822C9.50635 2.53135 9.22744 2.2501 8.87822 2.2501Z' fill='currentColor' />
    </svg>

  )
}
const FacebookIcon = (): JSX.Element => {
  return (
    <svg width='12' height='12' viewBox='0 0 12 12' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path d='M6.5788 12V6.53933H8.55978L8.85326 4.38202H6.5788V3.03371C6.5788 2.42697 6.79891 2.02247 7.75272 2.02247H9V0.0674157C8.77989 0.0674157 8.04619 0 7.23913 0C5.47826 0 4.23098 1.01124 4.23098 2.83146V4.38202H2.25V6.53933H4.23098V12H6.5788Z' />
    </svg>

  )
}
const MailIcon = (): JSX.Element => {
  return (

    <svg width='12' height='12' viewBox='0 0 12 12' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path d='M0.00250007 2.91267L5.99996 5.9114L11.9975 2.91263C11.9523 2.12486 11.2991 1.5 10.5 1.5H1.5C0.700888 1.5 0.0477261 2.12488 0.00250007 2.91267Z' />
      <path d='M12 4.58843L5.99996 7.58845L0 4.58847V9C0 9.82843 0.671573 10.5 1.5 10.5H10.5C11.3284 10.5 12 9.82843 12 9V4.58843Z' />
    </svg>

  )
}

const WebIcon = (): JSX.Element => {
  return (

    <svg width='12' height='12' viewBox='0 0 12 12' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6ZM4.49799 4.3793L8.82621 2.93654C8.97279 2.88768 9.11225 3.02713 9.06339 3.17371L7.62064 7.50195C7.60198 7.55794 7.55804 7.60187 7.50206 7.62053L3.17377 9.06333C3.02719 9.11219 2.88774 8.97274 2.9366 8.82616L4.3794 4.49788C4.39807 4.44189 4.442 4.39796 4.49799 4.3793ZM5.46967 5.4696C5.17678 5.76249 5.17678 6.23737 5.46967 6.53026C5.76256 6.82315 6.23744 6.82315 6.53033 6.53026C6.82322 6.23737 6.82322 5.76249 6.53033 5.4696C6.23744 5.17671 5.76256 5.17671 5.46967 5.4696Z' />
    </svg>

  )
}

const TelephoneIcon = (): JSX.Element => {
  return (
    <svg width='12' height='12' viewBox='0 0 12 12' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path d='M0 0.75C0 0.335786 0.335786 0 0.75 0H2.36465C2.73128 0 3.04418 0.26506 3.10445 0.626701L3.65893 3.95361C3.71306 4.27838 3.54904 4.60048 3.25455 4.74773L2.09344 5.32828C2.93067 7.40874 4.59126 9.06933 6.67172 9.90656L7.25227 8.74545C7.39952 8.45096 7.72162 8.28694 8.04639 8.34107L11.3733 8.89555C11.7349 8.95582 12 9.26872 12 9.63535V11.25C12 11.6642 11.6642 12 11.25 12H9.75C4.36522 12 0 7.63478 0 2.25V0.75Z' />
    </svg>
  )
}

const TwitterIcon = (): JSX.Element => {
  return (
    <svg width='12' height='12' viewBox='0 0 12 12' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path d='M10.7665 3.18283C10.7741 3.28942 10.7741 3.39604 10.7741 3.50263C10.7741 6.75388 8.2995 10.5001 3.77665 10.5001C2.38324 10.5001 1.08884 10.0965 0 9.39604C0.197977 9.41887 0.388312 9.42648 0.593906 9.42648C1.74363 9.42648 2.80202 9.03817 3.6472 8.37573C2.56598 8.35288 1.65989 7.64477 1.3477 6.67014C1.5 6.69297 1.65227 6.7082 1.81219 6.7082C2.03299 6.7082 2.25382 6.67773 2.45939 6.62446C1.33249 6.39602 0.487289 5.40618 0.487289 4.21075V4.1803C0.814687 4.36305 1.19543 4.47726 1.59895 4.49247C0.936516 4.05084 0.502523 3.29704 0.502523 2.44424C0.502523 1.9874 0.624328 1.56862 0.837539 1.20313C2.0482 2.69552 3.86801 3.67012 5.90859 3.77673C5.87053 3.59399 5.84768 3.40366 5.84768 3.2133C5.84768 1.85795 6.94413 0.753906 8.30707 0.753906C9.01519 0.753906 9.65477 1.05086 10.104 1.53055C10.6598 1.42396 11.1928 1.21837 11.6649 0.936648C11.4822 1.50773 11.0939 1.98742 10.5837 2.29197C11.0786 2.2387 11.5583 2.10161 12 1.91127C11.665 2.39856 11.2462 2.83255 10.7665 3.18283Z' />
    </svg>
  )
}

const YoutubeIcon = (): JSX.Element => {
  return (
    <svg width='12' height='12' viewBox='0 0 12 12' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M10.6885 2.00307C11.2059 2.14409 11.6125 2.55557 11.7499 3.07716C12 4.02375 12 6 12 6C12 6 12 7.97625 11.7499 8.92284C11.6106 9.44636 11.2039 9.85784 10.6885 9.99693C9.7531 10.25 6 10.25 6 10.25C6 10.25 2.24881 10.25 1.31149 9.99693C0.794146 9.85591 0.387528 9.44443 0.25008 8.92284C0 7.97625 0 6 0 6C0 6 0 4.02375 0.25008 3.07716C0.389437 2.55364 0.796055 2.14216 1.31149 2.00307C2.24881 1.75 6 1.75 6 1.75C6 1.75 9.7531 1.75 10.6885 2.00307ZM7.91855 6L4.80115 7.82171V4.1783L7.91855 6Z' />
    </svg>

  )
}

const TelegramIcon = (): JSX.Element => {
  return (
    <svg width='12' height='12' viewBox='0 0 12 12' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M12 6C12 9.3137 9.3137 12 6 12C2.6863 12 0 9.3137 0 6C0 2.6863 2.6863 0 6 0C9.3137 0 12 2.6863 12 6ZM6.57497 4.15892C5.54419 4.60416 2.5721 5.86858 2.5721 5.86858C1.86774 6.15354 2.28003 6.42067 2.28003 6.42067C2.28003 6.42067 2.88132 6.63439 3.39673 6.79465C3.91211 6.95493 4.18699 6.77685 4.18699 6.77685C4.18699 6.77685 5.38958 5.95764 6.60932 5.08498C7.46831 4.47949 7.26216 4.97814 7.05599 5.19186C6.60932 5.65489 5.8706 6.38505 5.25212 6.97275C4.97726 7.22209 5.11469 7.43578 5.23496 7.54263C5.58471 7.84942 6.40847 8.40737 6.78247 8.66068C6.8861 8.73087 6.9552 8.77767 6.97011 8.78926C7.05599 8.86052 7.53704 9.18106 7.82909 9.10983C8.12114 9.0386 8.1555 8.62898 8.1555 8.62898C8.1555 8.62898 8.37884 7.16865 8.58501 5.83297C8.62319 5.57072 8.66137 5.31397 8.69698 5.07457C8.78952 4.4523 8.86464 3.94718 8.87706 3.76712C8.92861 3.16162 8.31012 3.41094 8.31012 3.41094C8.31012 3.41094 6.97011 3.98084 6.57497 4.15892Z' />
    </svg>

  )
}

const TikTokIcon = (): JSX.Element => {
  return (
    <svg width='12' height='12' viewBox='0 0 12 12' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M8.65046 4.33615C9.48257 4.90718 10.4791 5.21226 11.5 5.20857V3.2554C11.2993 3.25594 11.0991 3.2352 10.9031 3.19355V4.74957C9.87988 4.75132 8.88193 4.44274 8.0502 3.86739V7.8844C8.04768 8.54355 7.86043 9.18976 7.50828 9.75451C7.15614 10.3193 6.65223 10.7815 6.05 11.0922C5.44777 11.4029 4.76966 11.5504 4.08758 11.5193C3.4055 11.4881 2.74488 11.2793 2.17576 10.9151C2.70218 11.4292 3.37482 11.781 4.10849 11.9259C4.84217 12.0708 5.60388 12.0022 6.29718 11.729C6.99048 11.4557 7.58418 10.99 8.0031 10.3907C8.42202 9.79154 8.64731 9.08581 8.65046 8.36292V4.33615ZM9.38898 2.34393C8.96583 1.90118 8.70618 1.33581 8.65046 0.735826V0.481915H8.08392C8.15396 0.863294 8.30635 1.22618 8.53119 1.54698C8.75603 1.86778 9.0483 2.13931 9.38898 2.34393ZM3.48756 9.3688C3.2912 9.12093 3.17087 8.82483 3.14026 8.5142C3.10964 8.20356 3.16998 7.89087 3.3144 7.61171C3.45882 7.33254 3.68153 7.09812 3.95717 6.93513C4.23281 6.77213 4.55031 6.6871 4.87355 6.68971C5.05201 6.68966 5.22943 6.71601 5.39962 6.76784V4.74957C5.20067 4.72414 5.00001 4.71326 4.79936 4.71702V6.28606C4.38496 6.15961 3.93642 6.18836 3.54298 6.36661C3.14953 6.54485 2.84 6.85952 2.67596 7.24803C2.51191 7.63654 2.50536 8.07042 2.65762 8.46338C2.80988 8.85633 3.10978 9.17957 3.49768 9.3688H3.48756Z' fill='#AFABD8' />
      <path fillRule='evenodd' clipRule='evenodd' d='M8.04991 3.85437C8.88164 4.42972 9.87959 4.7383 10.9028 4.73655V3.18053C10.3198 3.06154 9.79052 2.76892 9.38898 2.34393C9.0483 2.13931 8.75603 1.86778 8.53119 1.54698C8.30635 1.22618 8.15396 0.863294 8.08392 0.481915H6.5931V8.36292C6.59172 8.71176 6.47719 9.05142 6.26562 9.33423C6.05404 9.61703 5.75603 9.82877 5.41342 9.93973C5.07082 10.0507 4.70081 10.0553 4.35535 9.9529C4.0099 9.8505 3.70662 9.64624 3.48756 9.3688C3.1404 9.19971 2.86209 8.92264 2.69806 8.58231C2.53403 8.24198 2.49357 7.85823 2.58319 7.49297C2.67282 7.12772 2.88731 6.80226 3.19206 6.56912C3.49681 6.33597 3.87404 6.20873 4.26289 6.20793C4.4413 6.20848 4.61862 6.23482 4.78896 6.28606V4.71702C4.053 4.73407 3.33821 4.95819 2.73234 5.36186C2.12646 5.76553 1.65588 6.33118 1.37834 6.98938C1.1008 7.64758 1.02839 8.36969 1.16999 9.06705C1.3116 9.76442 1.66135 10.4067 2.17576 10.9151C2.745 11.2819 3.40637 11.4929 4.08998 11.5254C4.77359 11.558 5.45355 11.4109 6.05742 11.0999C6.66129 10.7889 7.16646 10.3256 7.51912 9.75933C7.87178 9.19308 8.05872 8.5451 8.06003 7.8844L8.04991 3.85437Z' />
      <path fillRule='evenodd' clipRule='evenodd' d='M10.9028 3.18053L10.903 2.76047C10.3676 2.76267 9.84268 2.61831 9.38898 2.34393C9.78951 2.77015 10.3193 3.06301 10.9028 3.18053ZM8.08392 0.481915C8.08392 0.407044 8.06021 0.328782 8.0501 0.253911V0H5.99303V7.88426C5.99125 8.32573 5.80809 8.74849 5.48376 9.05974C5.15942 9.37099 4.72041 9.54531 4.26308 9.54445C3.99379 9.54575 3.72811 9.48565 3.48756 9.3688C3.70662 9.64624 4.0099 9.8505 4.35535 9.9529C4.70081 10.0553 5.07082 10.0507 5.41342 9.93973C5.75603 9.82877 6.05404 9.61703 6.26562 9.33423C6.47719 9.05142 6.59172 8.71176 6.5931 8.36292V0.481915H8.08392ZM4.78914 4.71363V4.26766C3.94268 4.15666 3.08221 4.32568 2.348 4.74718C1.61378 5.16868 1.0491 5.8178 0.745985 6.58876C0.442871 7.35971 0.419195 8.20704 0.678816 8.99265C0.938438 9.77826 1.46615 10.456 2.17576 10.9151C1.66524 10.4055 1.3194 9.76336 1.18069 9.06727C1.04197 8.37117 1.11629 7.65114 1.39455 6.99514C1.67281 6.33915 2.14298 5.77559 2.74761 5.37332C3.35224 4.97104 4.06516 4.74747 4.79926 4.72991L4.78914 4.71363Z' />
    </svg>

  )
}

const MediumIcon = (): JSX.Element => {
  return (
    <svg width='12' height='12' viewBox='0 0 12 12' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path d='M6.77116 5.50002C6.77116 7.33304 5.25893 8.82145 3.38558 8.82145C1.51223 8.82145 0 7.33304 0 5.50002C0 3.667 1.51223 2.17859 3.38558 2.17859C5.25893 2.17859 6.77116 3.667 6.77116 5.50002ZM10.4803 5.50002C10.4803 7.22305 9.72038 8.62348 8.78746 8.62348C7.85455 8.62348 7.09467 7.22305 7.09467 5.50002C7.09467 3.77698 7.85455 2.37655 8.78746 2.37655C9.72038 2.37655 10.4803 3.76965 10.4803 5.50002ZM12 5.50002C12 7.04708 11.7367 8.30087 11.4056 8.30087C11.0746 8.30087 10.8113 7.04708 10.8113 5.50002C10.8113 3.95295 11.0746 2.69916 11.4056 2.69916C11.7367 2.69916 12 3.95295 12 5.50002Z' />
    </svg>

  )
}

export const getIcon = (key: string): JSX.Element => {
  switch (key) {
    case 'mail':
      return <MailIcon />
    case 'instagram':
      return <InstagramIcon />
    case 'linkedin':
      return <LinkedinIcon />
    case 'whatsapp':
      return <WhatsappIcon />
    case 'website':
      return <WebIcon />
    case 'telephone':
      return <TelephoneIcon />
    case 'tiktok':
      return <TikTokIcon />
    case 'twitter':
      return <TwitterIcon />
    case 'medium':
      return <MediumIcon />
    case 'youtube':
      return <YoutubeIcon />
    case 'telegram':
      return <TelegramIcon />
    case 'facebook':
    default:
      return <FacebookIcon />
  }
}
