import clsx from 'clsx'
import React from 'react'
import style from './style.module.css'

interface Props {
  children?: React.ReactNode
  className?: string
}

export const Grid = (props: Props): JSX.Element => {
  return (
    <div className={clsx(style['grid-wrapper'], props.className)}>
      <div className={style.grid}>
        {props.children}
      </div>
    </div>
  )
}
